import {
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Loader from 'src/components/Loader/Loader'
import { s3baseUrl } from 'src/config/config'
import Label from '../../components/Label'
import CustomPopover from 'src/components/CustomPopover'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import {
  _fetch_file_from_s3,
  _save_files_by_admin,
} from '../../DAL/source-code/source-code'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { LoadingButton } from '@mui/lab'
import moment from 'moment'
import { detail_project_for_admin } from 'src/DAL/projects/projects'

function EditSourceCode(props) {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

  const [CssFile, setCss] = useState('')
  const [htmlFile, setHtml] = useState('')
  const [JSFile, setJSFile] = useState('')
  const [Data, setData] = useState(location.state?.project)
  const [checked, setChecked] = React.useState(false)
  const [DetailClick, setDetailClick] = useState(true)
  const [SelectedPage, setSelectedPage] = useState('index')
  const [PagesList, setPagesList] = useState([])
  const handleBack = () => {
    navigate(-1)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()

    let postData = {
      project_id: params.project_id,
      html: htmlFile,
      css: CssFile,
      javascript: JSFile,
      type: params.project_type,
      page_slug: SelectedPage,
    }

    setIsLoadingUpdate(true)
    const edit_file = await _save_files_by_admin(postData)
    if (edit_file.code == 200) {
      enqueueSnackbar('Source code updated successfully.', {
        variant: 'success',
      })
    } else {
      enqueueSnackbar(edit_file.message, { variant: 'error' })
    }
    setIsLoadingUpdate(false)
  }

  const fetch_files_from_s3 = async () => {
    const html_file_path = `${s3baseUrl}/${params.project_id}/${SelectedPage}.html`
    const result_html = await _fetch_file_from_s3(html_file_path)
    setHtml(result_html)

    const css_file_path = `${s3baseUrl}/${params.project_id}/css/${
      SelectedPage == 'index' ? 'style' : SelectedPage
    }.css`
    const result_css = await _fetch_file_from_s3(css_file_path)
    setCss(result_css)

    const js_file_path = `${s3baseUrl}/${params.project_id}/js/${SelectedPage}.js`
    const result_js = await _fetch_file_from_s3(js_file_path)
    setJSFile(result_js)
    setIsLoading(false)
  }
  const handleChangePageData = (value) => {
    setIsLoading(true)
    setSelectedPage(value)
  }

  useEffect(async () => {
    fetch_files_from_s3()
  }, [SelectedPage])
  useEffect(async () => {
    const resp = await detail_project_for_admin(params.project_id)

    setPagesList([...resp.project.pages])
  }, [])

  if (isLoading) {
    return <Loader />
  }
  const handleMenuClick = () => {
    window.open(s3baseUrl + '/' + Data._id + '/' + SelectedPage + '.html')
    setDetailClick(!DetailClick)
  }
  console.log(
    s3baseUrl + '/' + Data._id + '/' + SelectedPage + '.html',
    '{Data.preview_link}',
  )
  const DETAIL_MENU = [
    {
      label: (
        <a
          href={s3baseUrl + '/' + Data._id + '/' + SelectedPage + '.html'}
          target="_blank"
        >
          Preview
        </a>
      ),
      icon: 'ic:baseline-remove-red-eye',
      handleClick: handleMenuClick,
    },
    // {
    //   label: 'Delete Page',
    //   icon: 'ep:delete',
    //   handleClick: {},
    // },
  ]

  return (
    <div>
      <div>
        <IconButton
          onClick={() => {
            handleBack()
          }}
          aria-label="delete"
        >
          <ArrowBackIcon />
        </IconButton>
      </div>

      <div className="col-12 d-flex justify-content-between">
        <div className="d-flex justify-content-between">
          <h4 className="m-1 text-capitalize mt-2">
            {location.state?.project?.project_name}
          </h4>
        </div>
        <Typography className="m-1" variant="h4" gutterBottom>
          {location.state?.project?.user_name}
        </Typography>

        <div className="p-3 d-flex justify-content-between">
          {/* <CustomPopover menu={DETAIL_MENU} /> */}
          <CustomPopover menu={DETAIL_MENU} />
        </div>
      </div>
      <Stack
        direction="row"
        style={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Stack>
          <FormControl>
            <InputLabel id="test-select-label">Page</InputLabel>
            <Select
              sx={{
                width: 150,
                fontSize: 14,
                color: 'black',
              }}
              labelId="test-select-label"
              label="Page"
              value={SelectedPage}
              onChange={(e) => {
                handleChangePageData(e.target.value)
              }}
              className="border-end"
            >
              {PagesList &&
                PagesList.length > 0 &&
                PagesList.map((item) => (
                  <MenuItem value={item.page_slug}>{item.page_name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={4}>
          <Stack direction="row" spacing={2} style={{ alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>Status :</Typography>
            <Label variant="ghost" color={Data.status ? 'success' : 'error'}>
              {Data.status ? 'Active' : 'Inactive'}
            </Label>
          </Stack>
          <Stack direction="row" spacing={2} style={{ alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>Published :</Typography>
            <Label
              variant="ghost"
              color={Data.is_published ? 'success' : 'error'}
            >
              {Data.is_published ? 'Yes' : 'No'}
            </Label>
          </Stack>
          <Stack direction="row" spacing={2} style={{ alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>SSL Config :</Typography>
            <Label
              variant="ghost"
              color={
                Data.ssl_status
                  ? Data.ssl_configured
                    ? 'success'
                    : 'info'
                  : 'error'
              }
            >
              {Data.is_published && Data.ssl_status
                ? Data.ssl_configured
                  ? 'Yes'
                  : 'Processing'
                : 'No'}
            </Label>
          </Stack>
        </Stack>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="html"
              value={htmlFile}
              multiline
              rows={25}
              onChange={(e) => setHtml(e.target.value)}
              label="HTML"
              name="html"
              autoFocus
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="css"
              value={CssFile}
              multiline
              rows={25}
              onChange={(e) => setCss(e.target.value)}
              label="CSS"
              name="css"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="js"
              value={JSFile}
              multiline
              rows={25}
              onChange={(e) => setJSFile(e.target.value)}
              label="JavaScript"
              name="js"
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              loading={isLoadingUpdate}
            >
              Update
            </LoadingButton>

            <Button
              className="mt-3"
              onClick={handleBack}
              fullWidth
              variant="outlined"
              color="primary"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default EditSourceCode

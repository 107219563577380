import { invokeApi } from "src/bl_libs/invokeApi";

export const _customer_list = async (page, rowsPerPage, postData) => {
  let requestObj = {
    path: `api/admin/list_customer?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _delete_customer = async (id) => {
  let requestObj = {
    path: `api/customer/delete_customer_for_admin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_customer_password = async (id, data) => {
  let requestObj = {
    path: `api/customer/update_customer_password_for_admin/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_customer_by_admin = async (data) => {
  let requestObj = {
    path: `api/customer/add_customer_by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_customer_token_for_login = async (data) => {
  let requestObj = {
    path: `api/app_api/get_token`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

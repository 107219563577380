import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import FormControl from "@mui/material/FormControl";

// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Input,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// import {
//   _add_customer_by_admin,
//   _update_customer_password,
// } from "src/DAL/customer/customer";
import { _add_helping_category } from "src/DAL/helping-categories/helping_categories";
import { convertFileSizeToBytes, get_local_preview } from "src/utils/utils";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import { _add_department } from "src/DAL/support_departments/support_departments";
// ----------------------------------------------------------------------
import { _plan_list_add } from "src/DAL/plans/plan";
import { toInteger } from "lodash";
// ----------------------------------------------------------------------

export default function AddPlans({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  handleRefresh,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    product_name: "",
    product_description: "",
    amount: "",
    currency: "USD",
    interval_time: "day",
    custom_domain_status: false,
    status: true,
    order: "",
    domains_count: "",
    assert_size: "",
    assert_size_unit: "MB",
    pages_version_count: "",
    pages_count: "",
    projects_version_count: "",
    projects_count: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSizeChange = (e) => {
    if (e.target.value.match(/^$|[0-9]+$/)) {
      setInputs({
        ...inputs,
        [e.target.name]:
          toInteger(e.target.value) + " " + inputs.assert_size_unit,
      });
    }
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeStatus = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: !inputs.custom_domain_status,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleUpdatePassword();
  };
  console.log(inputs);
  const handleUpdatePassword = async () => {
    const postData = {
      product_name: inputs.product_name,
      product_description: inputs.product_description,
      amount: inputs.amount,
      currency: inputs.currency,
      interval_time: inputs.interval_time,
      status: true,
      custom_domain_status: inputs.custom_domain_status,

      // domains_count: inputs.domains_count,
      assert_size: convertFileSizeToBytes(
        inputs.assert_size + " " + inputs.assert_size_unit
      ),

      pages_version_count: inputs.pages_version_count,
      pages_count: inputs.pages_count,
      projects_version_count: inputs.projects_version_count,
      projects_count: inputs.projects_count,
    };

    const _plan_list_add_resp = await _plan_list_add(postData);
    if (_plan_list_add_resp.code == 200) {
      onCloseDrawer();
      enqueueSnackbar(`Plan added successfully`, {
        variant: "success",
      });
      handleRefresh();
    } else {
      enqueueSnackbar(_plan_list_add_resp.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setInputs({
      product_name: "",
      product_description: "",
      currency: "USD",
      amount: "",
      interval_time: "day",
      status: true,
      domains_count: "",
      custom_domain_status: false,

      assert_size: "",
      assert_size_unit: "MB",
      pages_version_count: "",
      pages_count: "",
      projects_version_count: "",
      projects_count: "",
    });
    setIsLoading(false);
  }, [isOpenDrawer]);

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 350, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Add Plan
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              {/*  */}
              <TextField
                fullWidth
                label="Product"
                required
                name="product_name"
                value={inputs.product_name}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Product Description"
                required
                name="product_description"
                multiline
                rows={5}
                value={inputs.product_description}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Unit Amount"
                required
                name="amount"
                type="number"
                value={inputs.amount}
                onChange={handleChange}
              />

              <FormControl>
                <InputLabel id="demo-simple-select-label">Currency</InputLabel>

                <Select
                  label="Currency"
                  required
                  name="currency"
                  value={inputs.currency}
                  onChange={handleChange}
                >
                  <MenuItem value={"USD"}>USD</MenuItem>
                  <MenuItem value={"EUR"}>EUR</MenuItem>
                  <MenuItem value={"PKR"}>PKR</MenuItem>
                </Select>
              </FormControl>

              {/* <TextField
                fullWidth
                label="Domain Count"
                required
                name="domains_count"
                value={inputs.domains_count}
                onChange={handleChange}
              /> */}

              <Grid container>
                <Grid item xs={7}>
                  <TextField
                    fullWidth
                    label="Asset Size"
                    required
                    name="assert_size"
                    value={inputs.assert_size}
                    onChange={(e) => {
                      if (e.target.value.match(/^$|[0-9]+$/)) {
                        handleChange(e);
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Size</InputLabel>
                    <Select
                      label=" Size"
                      required
                      name="assert_size_unit"
                      value={inputs.assert_size_unit}
                      onChange={handleChange}
                    >
                      <MenuItem value={"MB"}>MB</MenuItem>
                      <MenuItem value={"GB"}>GB</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <TextField
                fullWidth
                label="Page Version Count"
                required
                name="pages_version_count"
                type="number"
                value={inputs.pages_version_count}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Page Count"
                required
                name="pages_count"
                type="number"
                value={inputs.pages_count}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Project Version Count"
                required
                name="projects_version_count"
                type="number"
                value={inputs.projects_version_count}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                type="number"
                label="Project Count"
                required
                name="projects_count"
                value={inputs.projects_count}
                onChange={handleChange}
              />
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Custom Domain Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs.custom_domain_status}
                  label="Custom Domain Status"
                  onChange={handleChange}
                  name="custom_domain_status"
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl> */}

              {/* <TextField
                fullWidth
                label="Time Interval"
                required
                name="interval_time"
                value={inputs.interval_time}
                onChange={handleChange}
              /> */}

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Time Interval
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs.interval_time}
                  label="Time Interval"
                  onChange={handleChange}
                  name="interval_time"
                >
                  <MenuItem value={"day"}>Day</MenuItem>
                  <MenuItem value={"month"}>Month</MenuItem>
                  <MenuItem value={"year"}>Year</MenuItem>
                </Select>
              </FormControl>

              <FormControlLabel
                className="justify-content-between"
                value="start"
                control={
                  <Switch
                    checked={inputs.custom_domain_status}
                    value={inputs.custom_domain_status}
                    onChange={handleChangeStatus}
                    color="primary"
                    name="custom_domain_status"
                  />
                }
                label="Custom Domain Status"
                labelPlacement="start"
              />
              <FormControlLabel
                className="justify-content-between"
                value="start"
                control={
                  <Switch
                    checked={inputs.status}
                    value={inputs.status}
                    onChange={handleChange}
                    color="primary"
                    name="status"
                  />
                }
                label="Status"
                labelPlacement="start"
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Add Plan
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}

import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Input,
  Button,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  _add_customer_by_admin,
  _update_customer_password,
} from "src/DAL/customer/customer";
import { _add_helping_category } from "src/DAL/helping-categories/helping_categories";
import { get_local_preview } from "src/utils/utils";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AddHelpingCategory({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  handleRefresh,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    name: "",
    image: "",
    description: "",
    file: "",
    status: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    console.log(e.target.type, "e.target.type ");
    if (e.target.type === "file") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.files[0],
      });
      return;
    }

    if (e.target.type === "checkbox") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleUpdatePassword();
  };
  console.log(inputs);
  const handleUpdatePassword = async () => {
    // upload image
    if (inputs.image) {
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs.image);
      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code == 200) {
        const { name, description, status } = inputs;
        const postData = {
          name,
          description,
          status,
          image: image_resp.file_name,
        };
        const add_customer_resp = await _add_helping_category(postData);
        if (add_customer_resp.code == 200) {
          onCloseDrawer();
          enqueueSnackbar(`Help category added successfully`, {
            variant: "success",
          });
          handleRefresh();
        } else {
          enqueueSnackbar(add_customer_resp.message, { variant: "error" });
        }
        setIsLoading(false);
      } else {
        enqueueSnackbar(image_resp.message, { variant: "error" });
        setIsLoading(false);
        return;
      }
    } else {
      const { name, description, status, image } = inputs;
      const postData = {
        name,
        description,
        status,
        image,
      };

      const add_customer_resp = await _add_helping_category(postData);
      if (add_customer_resp.code == 200) {
        onCloseDrawer();
        enqueueSnackbar(`Help category added successfully`, {
          variant: "success",
        });
        handleRefresh();
      } else {
        enqueueSnackbar(add_customer_resp.message, { variant: "error" });
      }
      setIsLoading(false);
    }
  };

  const handleClickRemove = (country) => {
    setInputs({
      ...inputs,
      image: "",
    });
  };

  useEffect(() => {
    setInputs({
      name: "",
      image: "",
      file: "",
      description: "",
      status: true,
    });
    setIsLoading(false);
  }, [isOpenDrawer]);
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 350, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Add Help Category
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              {/*  */}
              <TextField
                fullWidth
                label="Name"
                required
                name="name"
                value={inputs.name}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Description"
                required
                name="description"
                multiline
                rows={5}
                value={inputs.description}
                onChange={handleChange}
              />

              <FormControlLabel
                className="justify-content-between"
                value="start"
                control={
                  <Switch
                    checked={inputs.status}
                    value={inputs.status}
                    onChange={handleChange}
                    color="primary"
                    name="status"
                  />
                }
                label="Status"
                labelPlacement="start"
              />

              {inputs.image ? (
                <img src={get_local_preview(inputs.image)} alt="" />
              ) : (
                <label htmlFor="icon-button-file">
                  <Input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    value=""
                    name="image"
                    onChange={handleChange}
                  />
                  <Button
                    aria-label="upload picture"
                    component="span"
                    className="upload-button"
                    fullWidth
                  >
                    Upload Image
                  </Button>
                </label>
              )}

              {inputs.image && (
                <Button
                  onClick={handleClickRemove}
                  className="mt-1"
                  style={{ color: "Red" }}
                  fullWidth
                >
                  Remove
                </Button>
              )}

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Add Help Category
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}

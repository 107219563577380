import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Axios from "axios";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Label from "../../../components/Label";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../components/_dashboard/user";
//
import USERLIST from "../../../_mocks_/user";
import {
  _customer_list,
  _delete_customer,
  _get_customer_token_for_login,
} from "src/DAL/customer/customer";
import { useSnackbar } from "notistack";
import Loader from "src/components/Loader/Loader";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";

// import UpdateHelpingCategory from "../Update-Support-Department/UpdateSupportDepartment";
// import AddCustomer from "../Add-Support-Department/AddSupportDepartment";
import {
  _delete_helping_category,
  _helping_categories_listing,
} from "src/DAL/helping-categories/helping_categories";

import {
  _delete_department,
  _departments_listing,
} from "src/DAL/support_departments/support_departments";
import AddPlans from "../Add-Plans/Add-Plans";
import UpdatePlans from "../UpdatePlans/UpdatePlans";
import { _plan_list, _plan_list_dell } from "src/DAL/plans/plan";
import { _plan_list_add } from "src/DAL/plans/plan";
import { _plan_list_edit } from "src/DAL/plans/plan";
import { formatSizeUnits } from "src/utils/utils";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "product_name", label: "Product Name", alignRight: false },
  {
    id: "product_description",
    label: "Product Description",
    alignRight: false,
  },
  { id: "amount", label: "Unit Amount", alignRight: false },
  { id: "currency", label: "Currency", alignRight: false },
  { id: "interval_time", label: "Time Interval", alignRight: false },

  { id: "assert_size", label: "Asset Size", alignRight: false },
  { id: "pages_version_count", label: "Page Version Count", alignRight: false },
  { id: "pages_count", label: "Page Count", alignRight: false },
  {
    id: "projects_version_count",
    label: "Project Version Count",
    alignRight: false,
  },
  { id: "projects_count", label: "Project Count", alignRight: false },
  { id: "custom_domain_status", label: "Custom Domain", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.short_description.toLowerCase().indexOf(query.toLowerCase()) !==
          -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Listing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenAdd, setIsDrawerOpenAdd] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [product, setProduct] = useState({});

  const handleOpenChangePassword = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseChangePassword = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenAdd = () => {
    setIsDrawerOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setIsDrawerOpenAdd(false);
  };

  const handleDelete = async () => {
    console.log(selectedRow);
    const delete_resp = await _plan_list_dell(selectedRow._id);
    if (delete_resp.code == 200) {
      setOpenDelete(false);
      // get_customer_list(page, rowsPerPage);
      handleRefresh();
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };

  const handleUpdatePasswordClick = (row) => {
    setSelectedRow(row);
    handleOpenChangePassword();
  };
  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit Plan",
      icon: "akar-icons:edit",
      handleClick: handleUpdatePasswordClick,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
  ];

  const handleRefresh = () => {
    fetchProducts(page, rowsPerPage);
  };

  const fetchProducts = async () => {
    const resp = await _plan_list();
    if (resp.code == 200) {
      setProduct([...resp.data]);
      setData([...resp.data]);
      setIsLoading(false);

      console.log(resp.data, "DataDataDataDataDataDataDataData plan list");
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Plans
          </Typography>
          <Button
            onClick={handleOpenAdd}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Plan
          </Button>
        </Stack>

        <Card>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
          /> */}

          <>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                />
                <TableBody>
                  {data.map((row, i) => {
                    const {
                      _id,
                      product_name,
                      product_description,
                      amount,
                      currency,
                      interval_time,
                      domains_count,
                      assert_size,
                      pages_version_count,
                      pages_count,
                      projects_version_count,
                      projects_count,
                      custom_domain_status,
                      status,
                    } = row;


                    // function formatSizeUnits(bytes) {
                    //   if (bytes >= 1073741824) {
                    //     bytes = (bytes / 1073741824).toFixed(0) + " GB";
                    //   } else if (bytes >= 1048576) {
                    //     bytes = (bytes / 1048576).toFixed(0) + " MB";
                    //   } else if (bytes >= 1024) {
                    //     bytes = (bytes / 1024).toFixed(0) + " KB";
                    //   } else if (bytes > 1) {
                    //     bytes = bytes + " bytes";
                    //   } else if (bytes == 1) {
                    //     bytes = bytes + " byte";
                    //   } else {
                    //     bytes = "0 bytes";
                    //   }
                    //   return bytes;
                    // }
                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                        <TableCell>{i + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="text-capitalize"
                              variant="subtitle2"
                              noWrap
                            >
                              {product_name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {product_description}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {amount}
                        </TableCell>
                        <TableCell className="pl-0" align="left">
                          {currency}
                        </TableCell>
                        <TableCell className="pl-0" align="left">
                          {interval_time}
                        </TableCell>

                        

                        <TableCell className="pl-0" align="left">
                          {formatSizeUnits(assert_size)}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {pages_version_count}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {pages_count}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {projects_version_count}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {projects_count}
                        </TableCell>
                        <TableCell className="pl-0" align="left">
                          <Label
                            variant="ghost"
                            color={custom_domain_status ? "success" : "error"}
                          >
                            {custom_domain_status ? "True" : "Flase"}
                          </Label>
                        </TableCell>
                        <TableCell className="pl-0" align="left">
                          <Label
                            variant="ghost"
                            color={status ? "success" : "error"}
                          >
                            {status ? "Active" : "Inactive"}
                          </Label>
                        </TableCell>
                        
                        <TableCell align="right">
                          <CustomPopover data={row} menu={MENU_OPTIONS} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to delete this Plan?"}
              handleAgree={handleDelete}
            />
          </>

          {/* <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </div>
      <UpdatePlans
        isOpenDrawer={isDrawerOpen}
        onOpenDrawer={handleOpenChangePassword}
        onCloseDrawer={handleCloseChangePassword}
        selectedRow={selectedRow}
        handleRefresh={handleRefresh}
      />

      <AddPlans
        isOpenDrawer={isDrawerOpenAdd}
        onOpenDrawer={handleOpenAdd}
        onCloseDrawer={handleCloseAdd}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';
// routes
import Routers from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { ContextPGIMode } from './Hooks/PGIModeContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ContextPGIMode>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          TransitionComponent={Slide}
          maxSnack={1}
        >
          <Routers />
        </SnackbarProvider>
      </ThemeConfig>
    </ContextPGIMode>
  );
}

// material
import { Box, Grid, Container, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import Loader from "src/components/Loader/Loader";
import CustomDashboardCard from "src/components/_dashboard/app/CustomDashboardCard";
import { _admin_dashboard } from "src/DAL/login/login";
// components
import Page from "../components/Page";
import {
  SuccessCard,
  ErrorCard,
  InfoCard,
  WarningCard,
} from "../components/_dashboard/app";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { enqueueSnackbar } = useSnackbar();
  const [dashboard, setDashboard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const get_dashboard_data = async () => {
    const resp = await _admin_dashboard();

    if (resp.code == 200) {
      const _dashboard = resp.Dashboard;

      setDashboard(_dashboard);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_dashboard_data();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <SuccessCard
              label="Total Customers"
              count={dashboard.user_count}
              icon="clarity:users-line"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <InfoCard
              label="Wezily Customers"
              count={dashboard.accelerator_user_count}
              icon="clarity:users-solid-alerted"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <WarningCard
              label="Magnetizer Customers"
              count={dashboard.magnitizer_user_count}
              icon="clarity:users-outline-alerted"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <InfoCard
              label="Total Projects"
              count={dashboard.project_count}
              icon="el:website"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <SuccessCard
              label="Published Projects"
              count={dashboard.published_project_count}
              icon="entypo:publish"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <ErrorCard
              label="SSL Configured Projects"
              count={dashboard.ssl_configured_project_count}
              icon="fa:expeditedssl"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <InfoCard
              label="Help Article Categories"
              count={dashboard.helping_article_category_count}
              icon="carbon:collapse-categories"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InfoCard
              label="Help Articles"
              count={dashboard.helping_article_count}
              icon="ooui:articles-rtl"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

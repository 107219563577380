import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Label from "../../../components/Label";
import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../components/_dashboard/user";
//
import USERLIST from "../../../_mocks_/user";
import {
  _customer_list,
  _delete_customer,
  _get_customer_token_for_login,
} from "src/DAL/customer/customer";
import { useSnackbar } from "notistack";
import Loader from "src/components/Loader/Loader";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import { ProductFilterSidebar } from "src/components/_dashboard/products";
import UpdateHelpingCategory from "../Update-Helping-Article/UpdateHelpingCategory";
import AddCustomer from "../Add-Helping-Article/AddHelpingArticle";
import {
  _delete_helping_category,
  _helping_categories_listing,
} from "src/DAL/helping-categories/helping_categories";
import moment from "moment";
import { s3baseUrl } from "src/config/config";
import {
  _delete_helping_article,
  _helping_articles_listing,
} from "src/DAL/helping-articles/helping_articles";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  // { id: "image", label: "Image", alignRight: false },
  { id: "short_description", label: "Short Description", alignRight: false },
  { id: "help_article_category", label: "Category", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  // { id: "createdAt", label: "Created At", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.short_description.toLowerCase().indexOf(query.toLowerCase()) !==
          -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomerListing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenAdd, setIsDrawerOpenAdd] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleOpenChangePassword = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseChangePassword = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenAdd = () => {
    setIsDrawerOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setIsDrawerOpenAdd(false);
  };

  const handleDelete = async () => {
    console.log(selectedRow);
    const delete_resp = await _delete_helping_article(selectedRow._id);
    if (delete_resp.code == 200) {
      setOpenDelete(false);
      get_customer_list(page, rowsPerPage);
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const get_customer_list = async (page_num, rows_per_page) => {
    const resp = await _helping_articles_listing();
    if (resp.code == 200) {
      // setTotalPages(customer_list_resp.pages);

      setData(resp.helping_article);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const handleUpdatePasswordClick = (row) => {
    setSelectedRow(row);
    handleOpenChangePassword();
  };
  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit Article",
      icon: "akar-icons:edit",
      handleClick: handleUpdatePasswordClick,
    },

    {
      label: "Delete Article",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
  ];

  const handleRefresh = () => {
    get_customer_list(page, rowsPerPage);
  };

  useEffect(() => {
    get_customer_list(page, rowsPerPage);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container">
        {/* <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Help Articles
          </Typography>
          <Button
            onClick={handleOpenAdd}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Help Article
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const {
                        _id,
                        name,
                        image,
                        short_description,
                        status,
                        help_article_category,
                        createdAt,
                      } = row;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell>{i + 1}</TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography
                                className="text-capitalize"
                                variant="subtitle2"
                                noWrap
                              >
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          {/* <TableCell className="pl-0" align="left">
                            {image ? (
                              <a href={s3baseUrl + "/" + image} target="_blank">
                                <img
                                  width="40"
                                  height="40"
                                  src={s3baseUrl + "/" + image}
                                  alt=""
                                />
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </TableCell> */}

                          <TableCell className="pl-0" align="left">
                            {short_description}
                          </TableCell>

                          <TableCell className="pl-0" align="left">
                            {help_article_category
                              ? help_article_category.name
                              : "N/A"}
                          </TableCell>

                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={status ? "success" : "error"}
                            >
                              {status ? "Active" : "Inactive"}
                            </Label>
                          </TableCell>

                          {/* <TableCell className="pl-0" align="left">
                            {moment(createdAt).format("LLL")}
                          </TableCell> */}

                          <TableCell align="right">
                            <CustomPopover data={row} menu={MENU_OPTIONS} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to delete this help article?"}
              handleAgree={handleDelete}
            />
          </>

          {/* <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </div>
      <UpdateHelpingCategory
        isOpenDrawer={isDrawerOpen}
        onOpenDrawer={handleOpenChangePassword}
        onCloseDrawer={handleCloseChangePassword}
        selectedRow={selectedRow}
        handleRefresh={handleRefresh}
      />

      <AddCustomer
        isOpenDrawer={isDrawerOpenAdd}
        onOpenDrawer={handleOpenAdd}
        onCloseDrawer={handleCloseAdd}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

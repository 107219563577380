const { invokeApi } = require("src/bl_libs/invokeApi");

export const _plan_list = async () => {
  let requestObj = {
    path: `api/plan/get_product_plan`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: postData,
  };
  return invokeApi(requestObj);
};

export const _plan_list_add = async (data) => {
    console.log(data,"data");
    let requestObj = {
      path: `api/plan/add_product_and_plan`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  

  export const _plan_list_edit = async (id,data) => {
    console.log(data,"data");
    let requestObj = {
      path: `api/plan/edit_product_and_plan`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _plan_list_dell = async (id) => {
    // console.log(data,"data");
    let requestObj = {
      path: `api/plan/delete_product_and_plan/${id}`,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      // postData: data,
    };
    return invokeApi(requestObj);
  };


import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Label from "../../../components/Label";
import moment from "moment";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function ProjectList({ open, setOpen, data }) {
  const { projects } = data;
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => setOpen(false);

  useEffect(() => {}, [open]);

  return (
    <div>
      <Modal
        disableTypography="true"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-theme">
          <div clo-12 className="text-end modalIcon">
            <CloseIcon onClick={handleClose} className="pointer" />
          </div>
          <Typography
            className=" "
            id="spring-modal-title"
            variant="h6"
            component="h6"
          >
            Project List
          </Typography>
          <TableContainer>
            <Table>
              <TableRow>
                <TableCell>
                  <Typography className="text-capitalize" variant="subtitle2">
                    #
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Project Name
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  <Typography className="text-capitalize" variant="subtitle2">
                    ID
                  </Typography>
                </TableCell> */}
                <TableCell>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Published
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text-capitalize" variant="subtitle2">
                    SSL
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Status
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Created At
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Updated At
                  </Typography>
                </TableCell> */}
              </TableRow>
              <TableBody>
                {/* {projects &&
                  projects.map((project, index) => (
                    <TableRow hover key={index} tabIndex={-1} role="checkbox">
                      <TableCell className="pl-0" align="left">
                        {index + 1}
                      </TableCell>

                      <TableCell className="pl-0">
                        {project.project_name}
                      </TableCell>
                    </TableRow>
                  ))} */}

                {projects &&
                  projects.map((row, i) => {
                    const {
                      _id,
                      project_name,
                      user_name,
                      status,
                      is_published,
                      ssl_status,
                      updatedAt,
                      createdAt,
                      ssl_configured,
                    } = row;

                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="text-capitalize"
                              variant="subtitle2"
                            >
                              {project_name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        {/* <TableCell>{_id}</TableCell> */}
                        <TableCell className="pl-0" align="left">
                          <Label
                            variant="ghost"
                            color={(!is_published && "error") || "success"}
                          >
                            {is_published ? "Yes" : "No"}
                          </Label>
                        </TableCell>{" "}
                        <TableCell className="pl-0" align="left">
                          <Label
                            variant="ghost"
                            color={
                              is_published && ssl_status
                                ? ssl_configured
                                  ? "success"
                                  : "info"
                                : "error"
                            }
                          >
                            {is_published && ssl_status
                              ? ssl_configured
                                ? "Yes"
                                : "Processing"
                              : "No"}
                          </Label>
                        </TableCell>{" "}
                        <TableCell className="pl-0" align="left">
                          <Label
                            variant="ghost"
                            color={
                              (status === "banned" && "error") || "success"
                            }
                          >
                            {status ? "Active" : "Inactive"}
                          </Label>
                        </TableCell>
                        {/* <TableCell>{moment(createdAt).format("LLL")}</TableCell>
                        <TableCell>{moment(updatedAt).format("LLL")}</TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}

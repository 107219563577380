import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Input,
  Button,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  _add_customer_by_admin,
  _update_customer_password,
} from "src/DAL/customer/customer";
import {
  _add_helping_category,
  _update_helping_category,
} from "src/DAL/helping-categories/helping_categories";
import { get_local_preview } from "src/utils/utils";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import { s3baseUrl } from "src/config/config";
import { _update_department } from "src/DAL/support_departments/support_departments";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function UpdateHelpingCategory({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  handleRefresh,
  selectedRow,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    title: "",
    short_description: "",
    status: true,
    order: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    console.log(e.target.type, "e.target.type ");
    if (e.target.type === "file") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.files[0],
      });
      return;
    }

    if (e.target.type === "checkbox") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleUpdatePassword();
  };
  console.log(inputs);
  const handleUpdatePassword = async () => {
    // upload image
    if (inputs.file) {
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs.file);
      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code == 200) {
        const { name, description, status } = inputs;
        const postData = {
          name,
          description,
          status,
          image: image_resp.file_name,
        };
        const add_customer_resp = await _update_helping_category(
          selectedRow._id,
          postData
        );
        if (add_customer_resp.code == 200) {
          onCloseDrawer();
          enqueueSnackbar(`Support Department updated successfully`, {
            variant: "success",
          });
          handleRefresh();
        } else {
          enqueueSnackbar(add_customer_resp.message, { variant: "error" });
        }
        setIsLoading(false);
      } else {
        enqueueSnackbar(image_resp.message, { variant: "error" });
        setIsLoading(false);
        return;
      }
    } else {
      const { title, short_description, order, status } = inputs;
      const postData = {
        title,
        short_description,
        status,
        order,
        detailed_description: "",
      };

      const add_customer_resp = await _update_department(
        selectedRow._id,
        postData
      );
      if (add_customer_resp.code == 200) {
        onCloseDrawer();
        enqueueSnackbar(`Support Department updated successfully`, {
          variant: "success",
        });
        handleRefresh();
      } else {
        enqueueSnackbar(add_customer_resp.message, { variant: "error" });
      }
      setIsLoading(false);
    }
  };

  const handleClickRemove = (country) => {
    setInputs({
      ...inputs,
      image: "",
      file: "",
    });
  };

  useEffect(() => {
    const { title, short_description, status, order } = selectedRow;
    setInputs({
      title,
      short_description,
      status,
      order,
    });
    setIsLoading(false);
  }, [isOpenDrawer]);
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 350, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Edit Support Department
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              {/*  */}
              <TextField
                fullWidth
                label="Title"
                required
                name="title"
                value={inputs.title}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Description"
                required
                name="short_description"
                multiline
                rows={5}
                value={inputs.short_description}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Order"
                required
                name="order"
                type="number"
                value={inputs.order}
                onChange={handleChange}
              />

              <FormControlLabel
                className="justify-content-between"
                value="start"
                control={
                  <Switch
                    checked={inputs.status}
                    value={inputs.status}
                    onChange={handleChange}
                    color="primary"
                    name="status"
                  />
                }
                label="Status"
                labelPlacement="start"
              />

              {/* {inputs.file || inputs.image ? (
                <>
                  {inputs.file && (
                    <img src={get_local_preview(inputs.file)} alt="" />
                  )}
                  {inputs.image && (
                    <img src={s3baseUrl + "/" + inputs.image} alt="" />
                  )}
                </>
              ) : (
                <label htmlFor="icon-button-file">
                  <Input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    value=""
                    name="file"
                    onChange={handleChange}
                  />
                  <Button
                    aria-label="upload picture"
                    component="span"
                    className="upload-button"
                    fullWidth
                  >
                    Upload Image
                  </Button>
                </label>
              )}

              {(inputs.file || inputs.image) && (
                <Button
                  onClick={handleClickRemove}
                  className="mt-1"
                  style={{ color: "Red" }}
                  fullWidth
                >
                  Remove
                </Button>
              )} */}

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Update Support Department
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}

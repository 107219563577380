import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import closeFill from '@iconify/icons-eva/close-fill'
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
} from '@mui/material'
import Scrollbar from 'src/components/Scrollbar'
import { LoadingButton } from '@mui/lab'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import {
  _add_customer_by_admin,
  _update_customer_password,
} from 'src/DAL/customer/customer'
import CountrySelect from 'src/components/CountrySelector/CountrySelector'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AddCustomer({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  handleRefresh,
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    address: '',
    contact_number: '',
    business_name: '',
    business_country: '',
    postcode: '',
  })

  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    handleUpdatePassword()
  }

  const handleUpdatePassword = async () => {
    const postData = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      contact_number: inputs.contact_number,
      email: inputs.email,
      password: inputs.password,
      business_name: inputs.business_name,
      business_country: inputs.business_country,
      post_code: inputs.postcode,
      type: 1,
    }

    const add_customer_resp = await _add_customer_by_admin(postData)
    if (add_customer_resp.code == 200) {
      onCloseDrawer()
      enqueueSnackbar(`Customer added successfully`, {
        variant: 'success',
      })
      handleRefresh()
    } else {
      enqueueSnackbar(add_customer_resp.message, { variant: 'error' })
    }
    setIsLoading(false)
  }

  const handleCountryChange = (country) => {
    console.log(country, 'es')
    setInputs({
      ...inputs,
      business_country: country.label,
    })
  }

  useEffect(() => {
    setInputs({
      password: '',
      confirm_password: '',
    })
    setIsLoading(false)
  }, [isOpenDrawer])
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 350, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Add Customer
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              {/*  */}
              <TextField
                fullWidth
                label="Email"
                required
                name="email"
                type="email"
                value={inputs.email}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="First Name"
                required
                name="first_name"
                value={inputs.first_name}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Last Name"
                required
                name="last_name"
                value={inputs.last_name}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Address"
                required
                name="address"
                value={inputs.address}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Contact Number"
                required
                type="number"
                name="contact_number"
                value={inputs.contact_number}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Password"
                required
                name="password"
                value={inputs.password}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Business Name"
                required
                name="business_name"
                value={inputs.business_name}
                onChange={handleChange}
              />

              <CountrySelect onChange={handleCountryChange} />

              <TextField
                fullWidth
                label="Post Code"
                required
                name="postcode"
                value={inputs.postcode}
                onChange={handleChange}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Add Customer
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
      {/* </Form> */}
      {/* </FormikProvider> */}
    </>
  )
}
